import _ from "lodash";
import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_WPS_CONFIG_SETTINGS:
      return { ..._.first(action.payload) };
    case type.CREATE_WPS_CONFIG_SETTING:
      return { ..._.first([action.payload]) };
    case type.EDIT_WPS_CONFIG_SETTING:
      return { ..._.first([action.payload]) };
    // return { ...state, [action.payload.Id]: action.payload };
    default:
      return state;
  }
};
