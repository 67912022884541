import _ from "lodash";
import * as type from "./types";
export default (state = [], action) => {
  switch (action.type) {
    case type.FETCH_SETTLEMENTS:
      // return { ..._.mapKeys(action.payload, "Id") };
      return { [action.payload]: action.payload };

    case type.FETCH_SETTLEMENT:
      return { [action.payload]: action.payload };
    case type.CREATE_SETTLEMENT:
      return { [action.payload]: action.payload };
    case type.EDIT_SETTLEMENT:
      return { [action.payload]: action.payload };
    case type.CLEAR_SETTLEMENT:
      return [];
    default:
      return state;
  }
};
