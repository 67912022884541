import _ from "lodash";
import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_PAYROLL_ALLOWANCES:
      return { ..._.mapKeys(action.payload, "Id") };
    case type.CREATE_PAYROLL_ALLOWANCE:
      return { ...state, ..._.mapKeys(action.payload, "Id") };
    case type.EDIT_PAYROLL_ALLOWANCE:
      return { ...state, ..._.mapKeys(action.payload, "Id") };
    case type.DELETE_PAYROLL_ALLOWANCE:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
