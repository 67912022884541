import { store } from "../../../store";
export const GetState = () => store.getState();
export const _dbIds = async () => await store.getState().db_Ids;
export const _auth = async () => await store.getState().auth;
export const masterParameters = async () =>
  await store.getState().masterParameters;
export const _local_payroll_values = async () =>
  await store.getState().local_payroll_values;
export const _payrollMonthYear = async () =>
  await store.getState().PayrollMonthYear;
