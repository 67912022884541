import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.LOGIN:
      return action.payload;
    case type.LOGOUT:
      return action.payload;
    default:
      return state;
  }
};
