import _ from "lodash";
import * as types from "./types";
export default (state = [], action) => {
  switch (action.type) {
    case types.FETCH_MASTER_PARAMETERS:
      return { ...state, ..._.mapKeys(action.payload, "Id") };
    case types.CREATE_MASTER_PARAMETER:
      return { ...state, [action.payload.Id]: action.payload };
    case types.UPDATE_MASTER_PARAMETER:
      return { ...state, [action.payload.Id]: action.payload };
    case types.EDIT_MASTER_PARAMETERS:
      return { ...state, ..._.mapKeys(action.payload, "Id") };
    case types.DELETE_MASTER_PARAMETER:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
export const LateMinutesDeductionFormulas = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_MASTER_LATE_MINUTES_DEDUCTION_FORMULAS:
      return { ...state, ..._.mapKeys(action.payload, "Id") };
    default:
      return state;
  }
};
