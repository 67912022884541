import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_ADMIN_DASHBOARD:
      return action.payload;
    case type.FETCH_MANAGER_DASHBOARD:
      return action.payload;
    default:
      return state;
  }
};
export const EssPendingRequests = (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_EMPLOYEE_PENDING_REQUESTS:
      return action.payload;

    default:
      return state;
  }
};
export const EssReminders = (state = [], action) => {
  switch (action.type) {
    case type.FETCH_ESS_REMINDERS:
      return action.payload;

    default:
      return state;
  }
};
