import * as type from "./types";
import _ from "lodash";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_ATTENDANCE_PERIOD:
      return { ..._.mapKeys(action.payload, "value") };
    case type.CREATE_ATTENDANCE_PERIOD:
      return { ...state, ..._.mapKeys(action.payload, "value") };
    case type.DELETE_ATTENDANCE_PERIOD:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
export const AttendanceDDL = (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_ATTENDANCE_DDL:
      return action.payload;
    default:
      return state;
  }
};
