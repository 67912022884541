import _ from "lodash";
import * as type from "./types";
export const EmployeeBasicDetails = (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_EMPLOYEE_BASIC_DETAILS:
      return action.payload;
    default:
      return state;
  }
};
export const EmployeeSalaryDetails = (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_EMPLOYEE_SALARY_DETAILS:
      return action.payload;
    default:
      return state;
  }
};
export const EntitledLeaveDaysDetails = (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_ENTITLED_LEAVE_DETAILS:
      return action.payload;
    default:
      return state;
  }
};
