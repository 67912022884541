import _ from "lodash";
import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_VEHICLES:
      return { ..._.mapKeys(action.payload, "Id") };
    case type.FETCH_VEHICLE:
      return { ...state, [action.payload.Id]: action.payload };
    case type.CREATE_VEHICLE:
      return { ...state, [action.payload.Id]: action.payload };
    case type.EDIT_VEHICLE:
      return { ...state, [action.payload.Id]: action.payload };
    case type.DELETE_VEHICLE:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};

// GetVehiclesDDL
export const VehiclesDDL = (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_VEHICLE_DLL:
      return { ...state, [action.payload]: action.payload };
    default:
      return state;
  }
};
