import _ from "lodash";
import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_PAYROLL_SALARY_DETAILS:
      return { ...action.payload };
    default:
      return state;
  }
};
