import _ from "lodash";
import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_COMPANIES:
      return { ..._.mapKeys(action.payload, "value") };
    case type.FETCH_COMPANY:
      return { [action.payload.Id]: action.payload };
    case type.CREATE_COMPANY:
      return { ...state, [action.payload.Id]: action.payload };
    case type.EDIT_COMPANY:
      return { ...state, [action.payload.Id]: action.payload };
    case type.DELETE_COMPANY:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
export const companiesDDL = (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_COMPANIES_DDL:
      // return { ..._.orderBy(action.payload, "value", "desc") };
      return { ..._.mapKeys(action.payload, "value") };
    // return { ...state, ..._.mapKeys(action.payload, "value") };
    default:
      return state;
  }
};
export const companyEmployeeDDL = (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_COMPANY_EMPLOYEES_DDL:
      return { ..._.mapKeys(action.payload, "value") };
    // return { ...state, ..._.mapKeys(action.payload, "value") };
    default:
      return state;
  }
};
