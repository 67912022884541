import _ from "lodash";
import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_SICK_LEAVES:
      return { ..._.mapKeys(action.payload, "Id") };
    case type.FETCH_SICK_LEAVE:
      return { ...state, [action.payload.Id]: action.payload };
    case type.CREATE_SICK_LEAVE:
      return { ..._.mapKeys(action.payload, "Id") };
    // return { ...state, [action.payload.Id]: action.payload };
    case type.EDIT_SICK_LEAVE:
      return { ...state, [action.payload.Id]: action.payload };
    case type.DELETE_SICK_LEAVE:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
