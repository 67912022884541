import _ from "lodash";
import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_MASTER_TASKS:
      return { ..._.mapKeys(action.payload, "Id") };
    case type.CREATE_MASTER_TASK:
      return { ...state, [action.payload.Id]: action.payload };
    case type.EDIT_MASTER_TASK:
      return { ...state, [action.payload.Id]: action.payload };
    case type.DELETE_MASTER_TASK:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
