import _ from "lodash";
import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_GRATUITY_RULES_RESIGNATION_UN_LIMITEDS:
      return { ..._.mapKeys(action.payload, "Id") };
    case type.FETCH_GRATUITY_RULES_RESIGNATION_UN_LIMITED:
      return { ...state, [action.payload.Id]: action.payload };
    case type.CREATE_GRATUITY_RULES_RESIGNATION_UN_LIMITED:
      return { ...state, [action.payload.Id]: action.payload };
    case type.EDIT_GRATUITY_RULES_RESIGNATION_UN_LIMITED:
      return { ...state, [action.payload.Id]: action.payload };
    case type.DELETE_GRATUITY_RULES_RESIGNATION_UN_LIMITED:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
