import _ from "lodash";
import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_USERS:
      return { ..._.mapKeys(action.payload, "Id") };
    case type.FETCH_USER:
      return { [action.payload.Id]: action.payload };
    case type.CREATE_USER:
      return { ...state, [action.payload.Id]: action.payload };
    case type.EDIT_USER:
      return { ...state, [action.payload.Id]: action.payload };
    case type.DELETE_USER:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
