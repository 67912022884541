import { EMPLOYEE_BASIC_DDL } from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_BASIC_DDL:
      //  return { ...state, data: action.payload };
      return { ...state, [action.payload]: action.payload };
    default:
      return state;
  }
};
