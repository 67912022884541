import _ from "lodash";
import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_ASSETS:
      return { ..._.mapKeys(action.payload, "Id") };
    case type.FETCH_ASSET:
      return { ...state, [action.payload.Id]: action.payload };
    case type.CREATE_ASSET:
      return { ...state, [action.payload.Id]: action.payload };
    case type.EDIT_ASSET:
      return { ...state, [action.payload.Id]: action.payload };
    case type.DELETE_ASSET:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
