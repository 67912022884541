import _ from "lodash";
import * as type from "./types";
export default (state = [], action) => {
  switch (action.type) {
    case type.FETCH_EMPLOYEES:
      return { ..._.mapKeys(action.payload, "Id") };
    case type.FETCH_EMPLOYEE:
      return { [action.payload.Id]: action.payload };
    // case type.FETCH_EMPLOYEE:
    //   return { ...state, [action.payload.Id]: action.payload };
    case type.CREATE_EMPLOYEE:
      return { ...state, [action.payload.Id]: action.payload };
    case type.EDIT_EMPLOYEE:
      return { ...state, [action.payload.Id]: action.payload };
    case type.DELETE_EMPLOYEE:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
