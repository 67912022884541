import _ from "lodash";
import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_ATTENDANCE_DETAILS:
      return { ..._.mapKeys(action.payload, "Id") };
    case type.EDIT_ATTENDANCE_DETAILS:
      return { ...state, ..._.mapKeys(action.payload, "Id") };
    default:
      return state;
  }
};
