import * as types from "./types";
import _ from "lodash";
export default (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_EMPLOYEE_DEPENDENT_DETAILS:
      return { ..._.mapKeys(action.payload, "Id") };
    case types.FETCH_EMPLOYEE_DEPENDENT_DETAIL:
      return { [action.payload.Id]: action.payload };
    case types.CREATE_EMPLOYEE_DEPENDENT_DETAIL:
      return { ...state, [action.payload.Id]: action.payload };
    case types.EDIT_EMPLOYEE_DEPENDENT_DETAIL:
      return { ...state, [action.payload.Id]: action.payload };
    case types.DELETE_EMPLOYEE_DEPENDENT_DETAIL:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
