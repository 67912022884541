import _ from "lodash";
import * as types from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_PERSONAL_DETAILS:
      return { ..._.mapKeys(action.payload, "Id") };
    // return { ...state, ..._.mapKeys(action.payload, "Id") };
    case types.FETCH_PERSONAL_DETAIL:
      return { [action.payload.EmployeeId]: action.payload };
    case types.CREATE_PERSONAL_DETAIL:
      return { ...state, [action.payload.EmployeeId]: action.payload };
    case types.EDIT_PERSONAL_DETAIL:
      return { ...state, [action.payload.EmployeeId]: action.payload };
    case types.DELETE_PERSONAL_DETAIL:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
