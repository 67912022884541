import * as types from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_SALARY_DETAIL:
      return { [action.payload.EmployeeId]: action.payload };
    case types.CREATE_SALARY_DETAIL:
      return { ...state, [action.payload.EmployeeId]: action.payload };
    case types.EDIT_SALARY_DETAIL:
      return { ...state, [action.payload.EmployeeId]: action.payload };
    default:
      return state;
  }
};
