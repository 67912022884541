import { createStore, applyMiddleware, compose } from "redux";
import { persistStore } from "redux-persist";
import reduxThunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "../redux/root-reducer";
// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancer =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;
const middleware = [];

if (process.env.NODE_ENV === "development") {
  middleware.push(logger);
}
// export const store = createStore(
//   rootReducer,
//   // composeEnhancer(applyMiddleware(reduxThunk, ...middleware))
//   composeEnhancer(applyMiddleware(reduxThunk))
// );
// // export const store = createStore(
// //   rootReducer,
// //   composeEnhancer(applyMiddleware(...middlewares))
// // );

// export const persistor = persistStore(store);

// export default { store, persistStore };

export const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(reduxThunk))
  // composeEnhancer(applyMiddleware(reduxThunk, ...middleware))
);

export const persistor = persistStore(store);

export default { store, persistor };
