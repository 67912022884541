export const CREATE_MASTER_LATE_MINUTES_DEDUCTION =
  "CREATE_MASTER_LATE_MINUTES_DEDUCTION";
export const FETCH_MASTER_LATE_MINUTES_DEDUCTIONS =
  "FETCH_MASTER_LATE_MINUTES_DEDUCTIONS";
export const FETCH_MASTER_LATE_MINUTES_DEDUCTION =
  "FETCH_MASTER_LATE_MINUTES_DEDUCTION";
export const EDIT_MASTER_LATE_MINUTES_DEDUCTION =
  "EDIT_MASTER_LATE_MINUTES_DEDUCTION";
export const DELETE_MASTER_LATE_MINUTES_DEDUCTION =
  "DELETE_MASTER_LATE_MINUTES_DEDUCTION";
