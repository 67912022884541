import _ from "lodash";
import * as types from "./types";

export default (state = {}, action) => {
  switch (action.type) {
    case types.GET_REPORTS:
      return { ..._.mapKeys(action.payload, "Id") };
    default:
      return state;
  }
};
export const ReportGroupsDDL = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_REPORT_GROUPS:
      return { ..._.mapKeys(action.payload, "value") };
    default:
      return state;
  }
};
export const ReportsDDL = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_REPORTS:
      return { ..._.mapKeys(action.payload, "value") };
    default:
      return state;
  }
};
export const ReportDetails = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_REPORT_DETAILS:
      return action.payload;
    default:
      return state;
  }
};
