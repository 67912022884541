import * as types from "./types";
import _ from "lodash";
export default (state = [], action) => {
  switch (action.type) {
    case types.FETCH_GRATUITY_MASTER_DDL:
      return { ..._.mapKeys(action.payload, "value") };
    case types.CREATE_GRATUITY_MASTER:
      return { ...state, ..._.mapKeys(action.payload, "value") };
    case types.EDIT_GRATUITY_MASTER:
      return { ...state, ..._.mapKeys(action.payload, "value") };
    case types.DELETE_GRATUITY_MASTER:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
