export const CREATE_GRATUITY_RULES_RESIGNATION_UN_LIMITED =
  "CREATE_GRATUITY_RULES_RESIGNATION_UN_LIMITED";
export const FETCH_GRATUITY_RULES_RESIGNATION_UN_LIMITEDS =
  "FETCH_GRATUITY_RULES_RESIGNATION_UN_LIMITEDS";
export const FETCH_GRATUITY_RULES_RESIGNATION_UN_LIMITED =
  "FETCH_GRATUITY_RULES_RESIGNATION_UN_LIMITED";
export const EDIT_GRATUITY_RULES_RESIGNATION_UN_LIMITED =
  "EDIT_GRATUITY_RULES_RESIGNATION_UN_LIMITED";
export const DELETE_GRATUITY_RULES_RESIGNATION_UN_LIMITED =
  "DELETE_GRATUITY_RULES_RESIGNATION_UN_LIMITED";
