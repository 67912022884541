import _ from "lodash";
import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_WPS_EXCEL_CONFIGS:
      return { ..._.mapKeys(action.payload, "DisplayOrder") };
    // return action.payload;
    case type.SORT_WPS_EXCEL_CONFIGS:
      return action.payload;
    case type.FETCH_WPS_EXCEL_CONFIG:
      return { ...state, [action.payload.DisplayOrder]: action.payload };
    case type.CREATE_WPS_EXCEL_CONFIG:
      return { ...state, [action.payload.DisplayOrder]: action.payload };
    case type.EDIT_WPS_EXCEL_CONFIG:
      return { ...state, [action.payload.DisplayOrder]: action.payload };
    case type.EDIT_WPS_EXCEL_CONFIGS:
      return { ..._.mapKeys(action.payload, "DisplayOrder") };
    // return action.payload;
    case type.DELETE_WPS_EXCEL_CONFIG:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
