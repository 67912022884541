import _ from "lodash";
import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_ROSTERS:
      return _.mapKeys(action.payload, "Id");
    case type.FETCH_ROSTER:
      return { ...state, [action.payload.Id]: action.payload };
    // case type.CREATE_ROSTER:
    //   return { ...state, ..._.mapKeys(action.payload, "EmployeeId") };
    // return { ...state, [action.payload.Id]: action.payload };
    case type.CLEAR_ROSTER:
      return _.mapKeys(action.payload, "Id");
    case type.EDIT_ROSTER:
      return _.mapKeys(action.payload, "Id");
    // return { ...state, [action.payload.Id]: action.payload };
    // case type.DELETE_ROSTER:
    //   return _.omit(state, action.payload);
    default:
      return state;
  }
};
