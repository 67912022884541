import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_COMPANY_GROUP:
      return action.payload;
    case type.CREATE_COMPANY_GROUP:
      return { ...state, [action.payload.Id]: action.payload };
    case type.EDIT_COMPANY_GROUP:
      return { ...state, [action.payload.Id]: action.payload };

    default:
      return state;
  }
};
