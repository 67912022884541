import _ from "lodash";
import * as type from "./types";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_ATTENDANCE_SETTINGS:
      return { ..._.first(action.payload) };
    case type.CREATE_ATTENDANCE_SETTING:
      return { ..._.first([action.payload]) };
    case type.EDIT_ATTENDANCE_SETTING:
      return { ..._.first([action.payload]) };
    default:
      return state;
  }
};
